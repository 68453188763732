import * as React from "react";

function VendorIcon() {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29784 9.55812L5.03713 8.49895C4.5533 8.34154 4.5533 7.65704 5.03713 7.49962L8.29784 6.44046C8.51588 6.36994 8.63559 6.1356 8.56434 5.91764L8.31066 5.13698C8.24012 4.91902 8.00568 4.79936 7.78764 4.87059L4.52694 5.92975C4.0431 6.08717 3.6405 5.53301 3.93907 5.12131L5.95421 2.3484C6.08888 2.1632 6.04755 1.90322 5.86228 1.7686L5.19746 1.28567C5.01219 1.15105 4.75211 1.19236 4.61743 1.37755L2.60229 4.15047C2.30301 4.56217 1.65173 4.35062 1.65173 3.84205V0.415261C1.65173 0.185906 1.46575 0 1.2363 0H0.415426C0.18598 0 0 0.185906 0 0.415261V15.5847C0 15.8141 0.18598 16 0.415426 16H1.23702C1.46646 16 1.65244 15.8141 1.65244 15.5847V12.1579C1.65244 11.6494 2.30373 11.4378 2.60301 11.8495L4.61814 14.6224C4.75282 14.8076 5.01219 14.849 5.19817 14.7143L5.863 14.2314C6.04826 14.0968 6.08959 13.8368 5.95492 13.6516L3.93978 10.8787C3.6405 10.467 4.0431 9.91284 4.52765 10.0702L7.78835 11.1294C8.0064 11.1999 8.24083 11.081 8.31138 10.863L8.56505 10.0816C8.63559 9.86369 8.51659 9.62935 8.29855 9.55883L8.29784 9.55812Z"
        fill="#00DA93"
      />
    </svg>
  );
}

export default VendorIcon;
